@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
	:root {
		/* --font-sans: here if you have one */
		/* --font-mono: here if you got it... */

		/* prefixed with foreground because it should look good on the background */
		--foreground-destructive: 345 82.7% 40.8%;

		--background: 0 0% 100%;
		--foreground: 222.2 84% 4.9%;

		--muted: 210 40% 93%;
		--muted-foreground: 215.4 16.3% 30%;

		--popover: 0 0% 100%;
		--popover-foreground: 222.2 84% 4.9%;

		--card: 0 0% 100%;
		--card-foreground: 222.2 84% 4.9%;

		--border: 214.3 31.8% 91.4%;
		--input: 214.3 31.8% 91.4%;
		--input-invalid: 0 84.2% 60.2%;

		--primary: 222.2 47.4% 11.2%;
		--primary-foreground: 210 40% 98%;

		--secondary: 210 20% 83%;
		--secondary-foreground: 222.2 47.4% 11.2%;

		--accent: 210 40% 90%;
		--accent-foreground: 222.2 47.4% 11.2%;

		--destructive: 0 70% 50%;
		--destructive-foreground: 210 40% 98%;

		--ring: 215 20.2% 65.1%;

		--radius: 0.5rem;
	}

	.dark {
		--background: 222.2 84% 4.9%;
		--foreground: 210 40% 98%;

		/* prefixed with foreground because it should look good on the background */
		--foreground-destructive: -4 84% 60%;

		--muted: 217.2 32.6% 12%;
		--muted-foreground: 215 20.2% 65.1%;

		--popover: 222.2 84% 4.9%;
		--popover-foreground: 210 40% 98%;

		--card: 222.2 84% 4.9%;
		--card-foreground: 210 40% 98%;

		--border: 217.2 32.6% 17.5%;
		--input: 217.2 32.6% 17.5%;
		--input-invalid: 0 62.8% 30.6%;

		--primary: 210 40% 98%;
		--primary-foreground: 222.2 47.4% 11.2%;

		--secondary: 217.2 20% 24%;
		--secondary-foreground: 210 40% 98%;

		--accent: 217.2 32.6% 10%;
		--accent-foreground: 210 40% 98%;

		--destructive: 0 60% 40%;
		--destructive-foreground: 0 85.7% 97.3%;

		--ring: 217.2 32.6% 60%;
	}
}

@layer components {
	.empty-state {
		@apply flex flex-1 flex-col items-center justify-center gap-1;
	}

	.page-title {
		@apply flex items-center justify-center text-h3;
	}

	.sub-title {
		@apply flex items-center justify-start text-h5;
	}

	.main-card {
		@apply flex grow flex-col;
	}

	.main-container {
		padding: 0.25rem;
		border-width: 2px;
		width: 100%;
	}

	/* This is a hack to remove tailwind outline when using Radix UI */
	:focus-visible {
		outline: none;
	}
}

/* This is a hack to make the cursor pointer for Radix UI
	DOCS: https://www.radix-ui.com/themes/docs/theme/cursors
	DISCUSSION: https://github.com/radix-ui/themes/issues/123#issuecomment-1778862252
*/
.radix-themes {
	--cursor-button: pointer;
	--cursor-checkbox: pointer;
	--cursor-disabled: default;
	--cursor-link: pointer;
	--cursor-menu-item: pointer;
	--cursor-radio: pointer;
	--cursor-slider-thumb: grab;
	--cursor-slider-thumb-active: grabbing;
	--cursor-switch: pointer;
}

/* 
	This is a hack to make the navigation-menu.tsx full width.

	Radix UI adds an extra div with inline styles inside the 
	div with the class name `navMenu`.

	So, we need to override the width of the div with inline styles
*/
.navMenu div[style*='position: relative'] {
	width: 100% !important;
}

@keyframes collapsible-down {
	from {
		height: 0;
		opacity: 0;
	}
	to {
		height: var(--radix-collapsible-content-height);
		opacity: 1;
	}
}

@keyframes collapsible-up {
	from {
		height: var(--radix-collapsible-content-height);
		opacity: 1;
	}
	to {
		height: 0;
		opacity: 0;
	}
}

.animate-collapsible-down {
	animation: collapsible-down 0.3s ease-out;
}

.animate-collapsible-up {
	animation: collapsible-up 0.3s ease-out;
}
